import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
// import LanguageNavigation from "../components/language-navigation";
// import Header from "../components/header";
import Header from "../components/dynamic-components/header";
// import BottomNavigation from "../components/bottom-navigation";
import BottomNavigation from "../components/dynamic-components/bottom-navigation";
// import Footer from "../components/footer";
import Footer from "../components/dynamic-components/footer";
// import GeneralFeedback from "../components/bottom-navigation/general-feedback";
import GeneralFeedback from "../components/dynamic-components/general-feedback";
import { TransitionState } from "gatsby-plugin-transition-link";
import { motion } from "framer-motion";
import { useIntl } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
import SiteMetadata from "../components/site-metadata";

const InnerContainer = tw.div`max-w-screen-xl mx-auto`;

const StyledSection = tw.section`flex flex-col justify-center text-center px-10 min-h-screen items-center`;

const StyledHeadline = tw.h1`font-serif font-normal text-2xl leading-serif-2xl mb-4 px-12 md:text-3xl md:leading-serif-3xl lg:text-4xl lg:leading-serif-4xl lg:mb-6`;

const StyledSubheadline = tw.p`font-sans font-normal text-base leading-sans-base mb-16 md:text-lg md:leading-sans-lg lg:text-xl lg:leading-sans-xl`;

// const StyledLanguageNav = tw.div`lg:hidden`;

const StyledButton = styled(Link)`
	${tw`my-2 font-medium font-sans text-base leading-sans-base md:text-lg md:leading-sans-lg md:my-3 lg:text-xl lg:leading-sans-xl lg:my-4`}
`;

const HomeButton = tw(
	StyledButton
)`text-white rounded-full w-full py-5 shadow-help-options bg-gradient-to-r from-purple-gradient-light1 to-purple-gradient-light2 lg:py-6 md:w-max md:px-16 transition duration-150 ease-linear transform hover:scale-105`;

const NotFoundPage = ({ pageContext, data }) => {
	const intl = useIntl();
	const generalFeedbackData = data.allContentfulFeedbackComponent.nodes.filter(
		(item) => item.title === "General Feedback"
	)[0];

	const sectionAnimation = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0 },
	};

	const node = data.allContentful404Page.nodes[0];
	const headline = node.title;
	const subheadline = node.description;
	const buttonLabel = node.homeButton.label;

	return (
		<>
			<Helmet
				title={headline}
				htmlAttributes={{
					title: `${headline}`,
					lang: `${intl.locale}`,
				}}
			/>
			<SiteMetadata data={data.allContentfulSiteMetadata} />
			<Header data={data.allContentfulHeader} />
			<InnerContainer>
				<TransitionState>
					{({ mount }) => {
						return (
							<motion.div
								initial="hidden"
								animate={mount ? "visible" : ""}
								variants={sectionAnimation}
								transition={{ duration: 0.8 }}
							>
								<StyledSection>
									<StyledHeadline>{headline}</StyledHeadline>
									<StyledSubheadline>{subheadline}</StyledSubheadline>
									<HomeButton to="/">
										<div>{buttonLabel}</div>
									</HomeButton>
								</StyledSection>
							</motion.div>
						);
					}}
				</TransitionState>
			</InnerContainer>
			<Footer data={data.allContentfulFooter} />
			<BottomNavigation
				data={data.allContentfulBottomNavigation}
				languageData={data.allContentfulLanguageNavigation}
			/>
			<GeneralFeedback data={generalFeedbackData} />
		</>
	);
};

export const query = graphql`
	query NotFoundPageQuery($locale: String!) {
		allContentful404Page(filter: { node_locale: { eq: $locale } }) {
			nodes {
				title
				description
				homeButton {
					label
					title
				}
			}
		}
		allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...footer
			}
		}
		allContentfulHeader(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...header
			}
		}
		allContentfulBottomNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...bottomNavigation
			}
		}
		allContentfulFeedbackComponent(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...feedbackComponent
			}
		}
		allContentfulLanguageNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...languageNavigation
			}
		}
		allContentfulSiteMetadata(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...siteMetadata
			}
		}
	}
`;

export default NotFoundPage;
